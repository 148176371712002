var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("img", {
      staticClass: "profile-img float-none",
      class: { default: _vm.photo.error },
      attrs: { src: _vm.photoUrl },
      on: { error: _vm.onPhotoError }
    }),
    _c("svg", { attrs: { width: "0", height: "0" } }, [
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip" } }, [
          _c("path", {
            attrs: {
              d:
                "M" +
                (_vm.size / 2 + 10) +
                " 5 L" +
                (_vm.size - 5) +
                " " +
                (_vm.size / 2 - 10) +
                " Q" +
                (_vm.size + 5) +
                " " +
                _vm.size / 2 +
                " " +
                (_vm.size - 5) +
                " " +
                (_vm.size / 2 + 10) +
                " L" +
                (_vm.size / 2 + 10) +
                " " +
                (_vm.size - 5) +
                " Q" +
                _vm.size / 2 +
                " " +
                (_vm.size + 5) +
                " " +
                (_vm.size / 2 - 10) +
                " " +
                (_vm.size - 5) +
                " L 5 " +
                (_vm.size / 2 + 10) +
                " Q-5 " +
                _vm.size / 2 +
                " 5 " +
                (_vm.size / 2 - 10) +
                " L" +
                (_vm.size / 2 - 10) +
                " 5 Q " +
                _vm.size / 2 +
                " -5 " +
                (_vm.size / 2 + 10) +
                " 5z",
              fill: "url(#image)"
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }