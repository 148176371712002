<template>
  <span>
    <img
      :src="photoUrl"
      class="profile-img float-none"
      :class="{ default: photo.error }"
      @error="onPhotoError"
    />
    <svg width="0" height="0">
      <defs>
        <clipPath id="clip">
          <path
            :d="
              `M${size / 2 + 10} 5 L${size - 5} ${size / 2 - 10} Q${size +
                5} ${size / 2} ${size - 5} ${size / 2 + 10} L${size / 2 +
                10} ${size - 5} Q${size / 2} ${size + 5} ${size / 2 -
                10} ${size - 5} L 5 ${size / 2 + 10} Q-5 ${size / 2} 5 ${size /
                2 -
                10} L${size / 2 - 10} 5 Q ${size / 2} -5 ${size / 2 + 10} 5z`
            "
            fill="url(#image)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
</template>
<script>
export default {
  name: 'user-photo',
  props: {
    photo: {
      type: Object,
      default: () => {
        return {
          url: undefined
        }
      }
    },
    size: { type: Number, default: 96 }
  },
  computed: {
    photoUrl() {
      return this.photo.url
        ? this.photo.url
        : this.$store.state.userInfo.photoURL
    }
  },
  methods: {
    onPhotoError(event) {
      event.target.onerror = null
      this.photo.url = require('@/assets/svg/evenir-profil-default.svg')
      this.photo.error = true
    }
  }
}
</script>
<style lang="scss">
.profile-img {
  padding: 0;
  clip-path: url('#clip');
  object-fit: cover;

  &.default {
    padding: 25px;
  }
}
</style>
